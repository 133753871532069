




























































































































import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import DisplayFiles from "@/views/DisplayDocument.vue";
import Axios from 'axios';
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import {
    BASE_API_URL
} from "@/config";

@Component({
    components: {
        DisplayFiles
    }
})
export default class creditAuthorization extends Vue {
    public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
    
    @Prop()
    public creditAuthorization;

    @Prop({default: false})
    public loanIsLocked: boolean;

    public async uploadCreditAuthorization(event) {
        try {
            if (event.target.files.length > 0) {
                this.creditAuthorization.documents = [];
                Object.values(event.target.files).forEach(element => {
                    this.creditAuthorization.documents.push(element);
                });
            }
            this.changeStatusOfCreditAuthorization("uploaded");
        } catch (error) {
            console.log(error, "error");
        }
    }

    public changeStatusOfCreditAuthorization(from) {
        try {
            if (from == "byPassed") {
                this.creditAuthorization.status = "byPassed";
                this.creditAuthorization.documents = [];
            } else if (from == "uploaded") {
                this.creditAuthorization.status = "uploaded";
                this.creditAuthorization.byPassComment = null;
            }
            delete this.creditAuthorization.documentUploadStatus;
            delete this.creditAuthorization.rejectReason;
        } catch (error) {
            console.log(error);
        }
    }

    public checkStatusOfCreditAuthorizationUploadButton() {
        let returnVal = false;
        if (!this.creditAuthorization.status || (this.creditAuthorization.status == 'byPassed' && !this.creditAuthorization.documentUploadStatus))
            returnVal = true;
        else if (this.creditAuthorization.documents.length > 0 && !this.creditAuthorization.documents[0].hasOwnProperty('path'))
            returnVal = true;
        return returnVal;
    }

    public showCreditAuthorizationDocument(file) {
        if (this.creditAuthorization.status == 'uploaded' && this.creditAuthorization.documentUploadStatus && this.creditAuthorization.documentUploadStatus == 'Document not reviewed yet' && this.creditAuthorization.documents[0].hasOwnProperty('path'))
            this.reviewCreditAuthorization(this.$route.query.id);
        else {
            if (file[0].hasOwnProperty("path")) {
                this.$refs.docs["setInitialValuesForShowingSamplesWithDeleteButton"](file, null, null);
            } else {
                this.$refs.docs["setInitialValuesForLocalFiles"](file, null, null);
            }
        }
    }


    async reviewCreditAuthorization(loanTxnId) {
        try {
            let response = await Axios.post(BASE_API_URL + 'wemloprocessor/fetchDocumentOfCreditAuthorization', {
                loanTxnId: loanTxnId
            });
            this.$refs.docs['setInitialValues']([], [], response.data, true, loanTxnId);
        } catch (error) {
            console.log(error);
        }
    }

    async changeTheDocumentStatus(docId, check, comment) {
        this.creditAuthorization.status = check ? 'approved' : null;
        this.creditAuthorization.documentUploadStatus = check ? "Document approved" : "Document rejected";
        if (!check) {
            this.creditAuthorization.rejectReason = comment;
            this.creditAuthorization.documents = [];
        }
    }


    mounted() {
        // console.log(this.creditAuthorization,"dyh");
    }

}
